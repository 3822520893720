import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authenticated: false,
    user: null,
    sidebarShow: 'responsive',
    sidebarMinimize: false
  },
  mutations: {
    setAuth(state, user){
      localStorage.setItem('auth', JSON.stringify(user))
      state.authenticated = true
      state.user = user
    },
    

    initializeAuth(state){
      if(localStorage.getItem('auth'))
      {
        state.authenticated = true
        state.user = JSON.parse(localStorage.getItem('auth'))
      }
    },

    unAuth(state){
      state.authenticated= false
      state.user = null
      localStorage.removeItem('auth')
    },
    toggleSidebarDesktop (state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
      state[variable] = value
    }
  },
  actions: {
    async initAuth(context){
      console.log('in action iniauth')  
      try{
          
          let response = await window.axios.get('/api/user');
          context.commit('setAuth', response.data)
        }
        catch(error)
         {
            context.commit('unAuth')
         }
    },
   /* checkLogin(context){
      return new Promise((resolve, reject) =>{
        window.axios.get('/api/user').then(rep =>{
          context.commit('setAuth',rep.data)
          resolve()
        }, error => {
          context.commit('unAuth', error)
          reject()
        })
      })
    },*/
    async checkL({commit}){
      try{
        const rep = await window.axios.get('/api/user')
        commit('setAuth',rep.data)
      }
      catch(err)
      {
        commit('unAuth')
      }
    },
    /*login(context){},*/
    logout({commit}){
      commit('unAuth')
    }
  },
  getters:{
    isLoggedIn(state) {
      return !!state.authenticated
    }
  },
  modules: {
  }
})
