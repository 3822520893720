import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import Cloudinary from "cloudinary-vue";
import VueLoading from '@jxstjh/vue-loading-overlay';
import '@jxstjh/vue-loading-overlay/dist/vue-loading.css';
import VueConfirmDialog from 'vue-confirm-dialog'
import i18n from './i18n'
//import VueI18n from 'vue-i18n'


Vue.use(VueLoading)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(Cloudinary, {
  configuration: { 
    cloudName: "dwedq7j5n",
    secure: true }
});

Vue.config.productionTip = false
Vue.use(CoreuiVue)






store.dispatch('checkL').finally(() => {
  new Vue({
    router,
    store,
    icons,
    beforeCreate(){
      this.$store.commit('initializeAuth')
    },

    i18n,
    render: h => h(App)
  }).$mount('#app')
})


